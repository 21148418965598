.card-wizard[data-color="primary"] .nav-pills .nav-item .nav-link.checked, 
.card-wizard[data-color="primary"] .nav-pills .nav-item .nav-link.active {
  background: $primary!important;
  color: white;
}

.white-content {
  .enroll-security-key-page {
    .footer{
      .nav-link, .copyright {
        color: #fff!important;
      }

      .nav-link:hover {
        color: #9f000c!important;
      }
    }
  }
}

.footer {
  .nav-link:hover {
    color: #9f000c!important;
  }
}

.navbar-receiving {
  background: linear-gradient(0deg, #9f000c 0%, #87000b 100%)!important;
  color: #fff;
}