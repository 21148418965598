.card-header-sp {
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3% !important;

  img {
    max-width: 200px;
  }
}

.enroll-security-key-page {
  .card-header-sp img {
    max-width: 300px;
  }
}

.copyright .nav-link {
  display: contents;
}

#bg-video {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
}

.footer {
  .nav-link:hover {
    color: #9f000c!important;
  }
}