.full-page {
  &.enroll-security-key-page,
  &.pricing-page,
  &.login-page,
  &.lock-page,
  &.forgot-page,
  &.resend-page,
  &.register-page {
    min-height: 100vh;
    background: #9f000c;/*radial-gradient($background-black, $background-states-black); 
    /*background-image: url("../../../img/bg-dark.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
  }
}