.white-content {
  .footer .copyright {
    .nav-link {
      color:$black-states;
    }
  }

  .full-page {
    .footer .copyright {
      .nav-link {
        color:$black-states;
      }
    }
  }

  .dashboard .storage .custom .card {
    border: 2px solid #9f000c !important;
  }

  .custom-card {
    border: 1px solid #9f000c;
  }
}